@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'My Font';
  src: url('../assets/Benilla\ Calligraphy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.benilla {
  font-family: 'My Font', sans-serif;
}

/*  
.welcome-message {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-size: 14px;

}

#text {
  font-size: 24px;
    font-family: poppins, sans-serif;
    white-space: inherit;
    overflow: hidden;

    border-right: 5px solid orange; 
     
    margin: 0 auto;
    letter-spacing: .10em; 
    animation: 
      typing 2s steps(70, end),
      blink-caret .72s step-end infinite ;
}

.typing-indicator {
  width: 20px;
  height: 2px;
  background-color: black;
  margin-top: 10px;
  animation: typing 2s infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}

 */
.loading-path {
  stroke-dasharray: 140;
  stroke-dashoffset: 140;
  stroke-width: 4;
  stroke: #333;
  fill: none;
}

html,
body {
  height: 100%;
  width:100%;
  overflow-x: clip;
}



.box {
  transition: opacity 2s ease-in-out, transform 2.5s ease-in-out;
  animation: opacity 2s ease-in-out;
}

.home-container {
  animation: slideInFromBottom 1.0s ease-in-out;
}

.home-container-left {
  animation: slideInFromLeft 1.0s ease-in-out;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.home-container-top {
  animation: slideInFromTop 1.0s ease-in-out;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}


@keyframes opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}